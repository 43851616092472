@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-BoldItalic.eot');
    src: url('../assets/fonts/SofiaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-BoldItalic.woff') format('woff'),
        url('../assets/fonts/SofiaPro-BoldItalic.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-BoldItalic.svg#SofiaPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-Black.eot');
    src: url('../assets/fonts/SofiaPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-Black.woff') format('woff'),
        url('../assets/fonts/SofiaPro-Black.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-Black.svg#SofiaPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-MediumItalic.eot');
    src: url('../assets/fonts/SofiaPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-MediumItalic.woff') format('woff'),
        url('../assets/fonts/SofiaPro-MediumItalic.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-MediumItalic.svg#SofiaPro-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-Medium.eot');
    src: url('../assets/fonts/SofiaPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-Medium.woff') format('woff'),
        url('../assets/fonts/SofiaPro-Medium.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-Medium.svg#SofiaPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-Bold.eot');
    src: url('../assets/fonts/SofiaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-Bold.woff') format('woff'),
        url('../assets/fonts/SofiaPro-Bold.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-Bold.svg#SofiaPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-SemiBold.eot');
    src: url('../assets/fonts/SofiaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-SemiBold.woff') format('woff'),
        url('../assets/fonts/SofiaPro-SemiBold.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-SemiBold.svg#SofiaPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-SemiBoldItalic.eot');
    src: url('../assets/fonts/SofiaPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-SemiBoldItalic.woff') format('woff'),
        url('../assets/fonts/SofiaPro-SemiBoldItalic.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-SemiBoldItalic.svg#SofiaPro-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro-Italic.eot');
    src: url('../assets/fonts/SofiaPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro-Italic.woff') format('woff'),
        url('../assets/fonts/SofiaPro-Italic.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro-Italic.svg#SofiaPro-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../assets/fonts/SofiaPro.eot');
    src: url('../assets/fonts/SofiaPro.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SofiaPro.woff') format('woff'),
        url('../assets/fonts/SofiaPro.ttf') format('truetype'),
        url('../assets/fonts/SofiaPro.svg#SofiaPro') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

