.annexture-container,
.transaction-list-section,
.top-fund-list {
  .row {
    &:nth-child(2n + 1) {
      background: #ffffff 0% 0% no-repeat padding-box;
    }
    &:nth-child(2n) {
      background: #f9f9f9 0% 0% no-repeat padding-box;
    }
  }
}
.invest-section {
  border: 1px solid $color-08aeea;
  border-top: 3px solid #0093e9;
  border-radius: 5px 5px 10px 10px;
  .img-container {
    border: 1px solid $color-08aeea;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    display: inline-block;
    img {
      width: 60px;
      line-height: 0;
      height: 60px;
    }
  }
}
.fund-return-row {
  border: 0.75px solid $color-707070;
  .return-item {
    flex-grow: 1;
    flex-basis: 0;
    border-right: 0.75px solid $color-707070;
  }
  .return-item-2 {
    flex-grow: 2;
    flex-basis: 0;
  }
}
.profile-container {
  .nav-link {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-radius: 10px;
  }
  .selected {
    color: #08aeea;
    background: #08aeea0f 0% 0% no-repeat padding-box;
  }
  .profile-pic-container {
    position: relative;
    .profile-pic {
      width: 75px;
      height: 75px;
      border: 2px solid #08aeea;
    }
    .file-field {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.profile-container-mobile {
  .nav-link {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-radius: 10px;
  }
  .selected {
    color: #08aeea;
    background: #08aeea0f 0% 0% no-repeat padding-box;
  }
  .profile-pic-mobile {
    position: relative;
    .profile-mobile {
      width: 140px;
      height: 140px;
      border: 2px solid #08aeea;
    }
    .file-field {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.chat-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  img {
    width: 100px;
    cursor: pointer;
  }
}

.heading-custom {
  font-size: 36px;
  line-height: 48px;
  color: $color-222222;
}
.fund-section {
  color: #222222;
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
}
.transactions-container,
.protfolio-list-container {
  box-shadow: 0px 5px 50px #70707017;
  position: relative;
}
.otp-input {
  min-width: 40px;
  text-align: center;
  font-weight: 600;
  margin-right: 0.5em;
  padding: 0;
}
.text-disc {
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
.border-tl {
  border-top-left-radius: 2rem;
}

.border-tr {
  border-top-right-radius: 2rem;
}
.mr-20 {
  margin-right: 20%;
}
.invalid-feedback{
  display: block;
}
// new logo css
.tarrakkiLogo {
  width: 90px;
}
.vertical {
  .tarrakkiLogo {
    width: 55px;
  }
}
.logo-new{
  display: flex;
  justify-content: center;
}
.logo-new {
  p {
    font-size: 8px;
    margin: 0 3px 0 0;
  }
}
.sticky-top
  .logo-new {
    p {
      font-size: 8px;
      margin: 0 0 0 5px;
    }
}
.vl {
  border-left: 1px solid gray;
  height: 52px;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .logo-new {
    p {
      font-size: 7px;
      margin: 0 0 0 5px;
    }
  }
  .tarrakkiLogo {
    width: 50px !important;
  }
  .menu-bars{
    margin-left: 0px !important;
  }
  .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: nowrap; 
    align-items: center;
  }
  
  .navbar{
    padding:0.5rem 1rem 0.5rem 0.5rem
  }
}
// new logo css end