.mobile-login {
    background-color: #F9F9F9;
    padding: 1rem 3rem;
}
.mobile-heading-custom {
    background-color: #F9F9F9;
}
.mobile-signup {
    text-align: center;
    margin-top: 1rem;
}