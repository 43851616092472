/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px solid #99999966;
  border-radius: 7px;
  padding: 0.5rem 1em;
}
.form-control:focus {
  border-color: #99999966;
  outline: 0;
  box-shadow: none;
}
.updated-input {
  border: none;
  border-bottom: 1px solid #70707066 !important;
  border-radius: 0%;
  padding: 0.1rem 0.5rem;
  box-shadow: none !important;
}
.updated-select {
  border: 1px solid #70707066 !important;
  border-radius: 10px;
  padding: 0.75rem 0.5rem !important;
}

.disabled-input {
  @extend .updated-input;
  font-size: 9px;
}

.disabled-input:disabled {
  @extend .updated-input;
  background-color: lightgray !important;
}

.lightgray-backround {
  background-color: lightgray !important;
}

.input-group-text {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #70707066 !important;
  border-radius: 0;
  padding: 0;
}
.file-field {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .upload-container {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
}
.new-input-group > .form-control:not(:first-child) {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
