.order-status-container {
  .list-item-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .list-item-section {
      display: flex;
      align-items: center;
    }
    .list-bullet {
      float: left;
      border: 1px solid #707070;
      height: 10px;
      margin-right: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: white;
    }
    .list-status {
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      border: 1px solid $color-707070;
      background-color: #fff !important;
      min-width: 120px;
      display: inline-block;
      text-align: center;
      color: $color-707070;
    }
  }
  .list-line {
    background: #70707050;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 15px;
    left: 4px;
  }
  .completed,
  .success {
    border-color: $color-5ade53 !important;
    color: $color-5ade53 !important;
  }
  .pending {
    border-color: $color-d5a41d !important;
    color: $color-d5a41d !important;
  }
  .failed {
    border-color: $color-e42c2c !important;
    color: $color-e42c2c !important;
  }
  .inprogress {
    border-color: $color-707070 !important;
    color: $color-707070 !important;
  }
}
