.payment-step {
  border: 1px dashed #707070;
  border-radius: 10px;
  padding: 24px;
  position: relative;
  margin: 50px 24px;
  img {
    position: absolute;
    width: 50px;
    left: 50%;
    transform: translate(-50%);
    top: -25px;
  }
  span {
    padding: 24px;
  }
}
